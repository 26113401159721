<template>
	<div class="animated fadeIn">
		<b-form @submit.prevent="submitForm">
			<!-- <pre>{{ script }}</pre> -->
			<b-row>
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(1, 'License')">
						<v-select
							id="license"
							:clearable="false"
							v-model="script.licenceId"
							:options="licenseOptions"
							:reduce="(option) => option.id"
							@option:selected="handleChangeLicense"
							:class="{ 'is-invalid': isSubmitted && $v.script.licenceId.$error }"
						/>
						<div v-if="isSubmitted && !$v.script.licenceId.required" class="invalid-feedback">
							{{ FormMSG(20, 'Please, select a license') }}
						</div>
					</b-form-group>
				</b-col>
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(2, 'Company name')">
						<b-form-input id="company-name" v-model="script.companyName" type="text" disabled />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="script.type !== 20 ? FormMSG(3, 'Project (optional)') : FormMSG(40, 'Project')">
						<v-select
							id="project"
							v-model="script.projectId"
							label="titre"
							:options="projectOptions"
							:reduce="(option) => option.id"
							@input="deleteSelectedProject"
							:class="{ 'is-invalid': isSubmitted && $v.script.projectId.$error }"
						/>
						<div v-if="isSubmitted && $v.script.projectId.$error" class="invalid-feedback">
							{{ FormMSG(39, 'Please, select a project') }}
						</div>
					</b-form-group>
				</b-col>
				<b-col sm="12" md="12" lg="6" xl="6" class="d-flex align-items-center" v-if="!isComputationSalary && !isOdoo">
					<div class="info-script">
						{{ FormMSG(4, `* If no project is selected, applies to all license's project`) }}
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(5, 'Script friendly name')">
						<b-form-input
							id="script-friendly-name"
							v-model="script.name"
							type="text"
							:placeholder="FormMSG(6, 'Enter your script name...')"
							:class="{ 'is-invalid': isSubmitted && $v.script.name.$error }"
						/>
						<div v-if="isSubmitted && !$v.script.name.required" class="invalid-feedback">
							{{ FormMSG(21, 'Name is required') }}
						</div>
					</b-form-group>
				</b-col>
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(7, 'Script type')">
						<v-select
							id="script-type"
							:clearable="false"
							v-model="script.type"
							label="text"
							:options="typeOptions"
							:reduce="(option) => option.value"
							@option:selected="handleTypeChanged"
							:class="{ 'is-invalid': isSubmitted && $v.script.type.$error }"
						/>
						<div v-if="isSubmitted && !$v.script.type.required" class="invalid-feedback">
							{{ FormMSG(22, 'Please, select a type') }}
						</div>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="d-flex align-items-center">
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(8, 'Script file')">
						<b-form-file
							v-model="fileToUploaded"
							ref="scriptFile"
							class="mb-2"
							:accept="defaultScriptAcceptFiles"
							:placeholder="FormMSG(9, 'Choose a file or drop it here ...')"
							:browse-text="FormMSG(10, 'Browse')"
							:drop-placeholder="FormMSG(11, 'Drop file here ...')"
							:class="{ 'is-invalid': isSubmitted && $v.script.fileName.$error }"
							@change="handleChangeFileToUploaded"
						/>
						<div v-if="isSubmitted && !$v.script.fileName.required" class="invalid-feedback">
							{{ FormMSG(23, 'File is required') }}
						</div>
					</b-form-group>
				</b-col>
				<b-col
					sm="12"
					md="12"
					:lg="`${isOdoo && action === 'UPDATE' && odooBackLogXid !== null ? 4 : 6}`"
					:xl="`${isOdoo && action === 'UPDATE' && odooBackLogXid !== null ? 4 : 6}`"
					style="padding-top: 18px"
				>
					<b-form-group label="">
						<b-input-group>
							<b-form-input :value="script.fileName" :placeholder="FormMSG(12, 'File name ...')" disabled />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col sm="12" md="12" lg="2" xl="2" style="padding-top: 2px" v-if="isOdoo && action === 'UPDATE' && odooBackLogXid !== null">
					<b-button
						size="sm"
						variant="custom-outline-danger"
						class="d-flex justify-content-center align-items-center"
						id="odoo-log-file"
						@click="getOdooBackLog"
						block
					>
						<component :is="getLucideIcon('Download')" :size="20" :stroke-width="2.25" class="mr-2" />
						<div style="margin-top: 2px">{{ FormMSG(46, 'Error Log') }}</div>
					</b-button>
					<b-tooltip :show.sync="showLogFileTooltip" target="odoo-log-file" placement="top">
						<div>
							<strong>{{ FormMSG(45, 'Download back log') }}</strong>
						</div>
					</b-tooltip>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="12" md="12" lg="6" xl="6" v-if="!isComputationSalary && !isOdoo">
					<b-form-group :label="FormMSG(13, 'Language')">
						<v-select
							id="script-type"
							:clearable="false"
							v-model="script.language"
							label="text"
							:options="languageOptions"
							:reduce="(option) => option.value"
							:class="{ 'is-invalid': isSubmitted && $v.script.language.$error }"
						/>
						<div v-if="isSubmitted && !$v.script.language.required" class="invalid-feedback">
							{{ FormMSG(24, 'Please, select a language') }}
						</div>
					</b-form-group>
				</b-col>
				<b-col v-if="!isCertifiedScript && !isComputationSalary && !isOdoo" sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(28, 'Output format')">
						<v-select
							id="outputFileFormat"
							:clearable="false"
							v-model="script.outputFileFormat"
							:options="outputFormatOptions"
							label="text"
							:reduce="(option) => option.value"
							:class="{ 'is-invalid': isSubmitted && $v.script.outputFileFormat.$error }"
						/>
						<div v-if="isSubmitted && !$v.script.outputFileFormat.required" class="invalid-feedback">
							{{ FormMSG(29, 'Please, select a format') }}
						</div>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row v-if="!isCertifiedScript && !isComputationSalary && !isOdoo">
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(34, 'Custom Filename')">
						<b-form-input id="customExportFileName" v-model="script.customExportFileName" type="text" :placeholder="FormMSG(12, 'File name ...')" />
						<!-- :class="{ 'is-invalid': isSubmitted && $v.script.customExportFileName.$error }" -->
						<!-- <div v-if="isSubmitted && !$v.script.customExportFileName.required" class="invalid-feedback">
							{{ FormMSG(21, 'FileName is required') }}
						</div> -->
					</b-form-group>
				</b-col>
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(35, 'Process Number')">
						<b-form-input id="processNumber" v-model="script.processNumber" type="number" step="1" min="0" max="100000" placeholder="0" />
						<!-- :class="{ 'is-invalid': isSubmitted && $v.script.processNumber.$error }" -->
						<!-- <div v-if="isSubmitted && !$v.script.processNumber.required" class="invalid-feedback">
							{{ FormMSG(21, 'FileName is required') }}
						</div> -->
					</b-form-group>
				</b-col>
			</b-row>
			<b-row v-if="!isCertifiedScript && !isComputationSalary && !isOdoo">
				<b-col sm="12" md="12" lg="6" xl="6">
					<b-form-group :label="FormMSG(36, 'Javascript')">
						<b-form-file
							v-model="fileJsToUploaded"
							ref="javascriptFile"
							class="mb-2"
							:accept="supportedMimeTypesForJsInput"
							:placeholder="FormMSG(9, 'Choose a file or drop it here ...')"
							:browse-text="FormMSG(10, 'Browse')"
							:drop-placeholder="FormMSG(11, 'Drop file here ...')"
							@change="handleChangeFileJsToUploaded"
						/>
						<!-- :class="{ 'is-invalid': isSubmitted && $v.script.processingJsXid.$error }" -->
						<!-- <div v-if="isSubmitted && !$v.script.processingJsXid.required" class="invalid-feedback">
							{{ FormMSG(23, 'File is required') }}
						</div> -->
					</b-form-group>
				</b-col>
				<b-col sm="12" md="12" lg="6" xl="6" style="padding-top: 22px">
					<b-form-group label="">
						<b-form-input :value="script.fileNameJs" :placeholder="FormMSG(12, 'File name ...')" disabled />
					</b-form-group>
				</b-col>
			</b-row>
			<!-- <b-row> -->
			<fieldset class="my-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" v-if="script.outputFileFormat === 5">
				<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
					{{ FormMSG(30, 'Custom Script') + '&nbsp;(e.g: ion)' }}
				</legend>
				<b-row>
					<b-col sm="6" md="6" lg="6" xl="6">
						<b-form-group :label="FormMSG(31, 'Extension')">
							<b-form-input
								id="customExtension"
								v-model="script.customExtension"
								type="text"
								:placeholder="FormMSG(32, 'Enter your extension...')"
								:class="{ 'is-invalid': isSubmitted && $v.script.customExtension.$error }"
							/>
							<div v-if="isSubmitted && $v.script.customExtension.$error" class="invalid-feedback">
								{{ FormMSG(33, 'Extension is required') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<!-- </b-row> -->
			<b-row class="d-flex justify-content-center align-items-center" :style="`display: ${displaySubmitBtn ? 'block' : 'none'} !important;`">
				<b-col sm="12" md="12" lg="4" xl="4">
					<b-button ref="submit" type="submit" size="md" variant="primary" block>{{ FormMSG(25, 'Save') }}</b-button>
				</b-col>
			</b-row>
		</b-form>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getLicenses } from '@/cruds/license.crud';
import { getProjects } from '@/cruds/project.crud';
import { addScript, updateScript } from '@/cruds/script.crud';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { store } from '@/store';
import { isNil, noTilde, extensiontValid } from '@/shared/utils';
import _ from 'lodash';
import { capitalize } from '~utils';
import { isFileSupported } from '@/utils/isFileSupported';

const axios = require('axios').create();

export default {
	name: 'ScriptForm',
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		item: {
			type: Object,
			required: true,
			default: () => {}
		},
		displaySubmitBtn: {
			type: Boolean,
			required: false,
			default: true
		},
		action: {
			type: String,
			required: true,
			default: ''
		},
		odooBackLogXid: {
			type: String,
			required: false,
			default: null
		}
	},
	computed: {
		isCertifiedScript() {
			if ([15, 16, 17].includes(this.script.type)) {
				return true;
			}

			return false;
		},
		typeOptions() {
			let menus = this.FormMenu(1321);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			menus = [
				{
					value: null,
					text: this.FormMSG(15, 'Select a type ...')
				},
				...menus
			];

			return menus;
		},
		languageOptions() {
			let menus = this.FormMenu(1);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			menus = [
				{
					value: null,
					text: this.FormMSG(16, 'Select a language ...')
				},
				{
					value: -1,
					text: this.FormMSG(17, 'All')
				},
				...menus
			];

			return menus;
		},
		outputFormatOptions() {
			let menus = this.FormMenu(11007);

			menus.sort((a, b) => {
				if (a.text < b.text) {
					return -1;
				}
				if (a.text > b.text) {
					return 1;
				}

				return 0;
			});

			menus = [
				{
					value: null,
					text: this.FormMSG(27, 'Select a format ...')
				},
				...menus
			];

			return menus;
		},
		isOdoo() {
			if ([24].includes(this.script.type)) {
				return true;
			}

			return false;
		},
		isComputationSalary() {
			if ([20].includes(this.script.type)) {
				return true;
			}

			return false;
		},
		defaultScriptAcceptFiles() {
			if ([24].includes(this.script.type)) {
				return '.json';
			}

			if ([20].includes(this.script.type)) {
				return '.js';
			}

			if (!_.isNil(this.script.type) && ![20, 24].includes(this.script.type)) {
				return '.xlsx,.xlsm';
			}

			return '.xlsx,.xlsm,.js,.json';
		},
		supportedMimeTypesForJsInput() {
			return '.js';
		}
	},
	data() {
		return {
			script: {
				licenceId: null,
				projectId: null,
				type: null,
				language: null,
				fileName: '',
				outputFileFormat: null,
				customExtension: '',
				customExportFileName: '',
				processNumber: 0,
				fileNameJs: ''
			},
			licenseOptions: [
				{
					id: null,
					label: ''
				}
			],
			projectOptions: [
				{
					id: null,
					titre: ''
				}
			],
			isSubmitted: false,
			eventFile: null,
			uploadUrl: '/upload',
			parentType: 'scriptRecorder',
			fileToUploaded: null,
			oldScript: {},
			fileJsToUploaded: null,
			eventJsFile: null,
			showLogFileTooltip: true
		};
	},
	created() {
		this.$nextTick(async () => {
			await this.getLicenses();
		});
	},
	methods: {
		async submitForm(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			this.script.processNumber = isNaN(+this.script.processNumber) ? 0 : +this.script.processNumber;

			if (this.action === 'ADD') {
				this.$emit('script-form:loading', true);
				await addScript(this.script)
					.then(async (record) => {
						this.script = {
							...record
						};

						await this.upload().then(async (response) => {
							if (response) {
								response.fileName = this.eventFile.name;
								await updateScript(this.script.id, {
									templateImageChildXid: this.script.templateImageChildXid
								}).then((data) => {
									if (data && _.isNil(this.eventJsFile)) {
										this.$emit('script-form:successed', response);
										this.$emit('script-form:loading', false);
									}
								});
							}
						});

						if (!_.isNil(this.eventJsFile)) {
							await this.uploadJs()
								.then(async (response) => {
									if (response) {
										await updateScript(this.script.id, {
											processingJsXid: this.script.processingJsXid
										}).then((data) => {
											if (data) {
												this.$emit('script-form:successed', response);
												this.$emit('script-form:loading', false);
											}
										});
									}
								})
								.catch((error) => {
									this.createToastForMobile(
										this.FormMSG(37, 'Not valid file JS'),
										this.FormMSG(38, 'Please uplaod a file valid.'),
										'',
										'danger'
									);
									this.$emit('script-form:loading', false);
									this.eventJsFile = null;
									this.script.fileNameJs = '';
								});
						}
					})
					.catch((error) => {
						const { graphQLErrors } = error;
						let message = '';

						if (graphQLErrors && graphQLErrors[0].status === 400) {
							message = this.FormMSG(67, 'A salary computation has already created for this project.');
							this.createToastForMobile(
								this.FormMSG(41, 'Error'),
								!_.isNil(graphQLErrors[0].message) && !_.isEmpty(graphQLErrors[0].message) ? capitalize(graphQLErrors[0].message) : message,
								'',
								'danger'
							);
						}
						this.$emit('script-form:loading', false);
					});
			} else if (this.action === 'UPDATE') {
				this.$emit('script-form:loading', true);
				await updateScript(this.script.id, this.script)
					.then(async (record) => {
						this.script = {
							...record
						};

						if (!_.isNil(this.eventFile)) {
							await this.upload().then(async (response) => {
								if (response) {
									await updateScript(this.script.id, {
										templateImageChildXid: this.script.templateImageChildXid
									}).then((data) => {
										if (data && _.isNil(this.eventJsFile)) {
											this.$emit('script-form:successed', response);
											this.$emit('script-form:loading', false);
										}
									});
								}
							});
						} else {
							if (_.isNil(this.eventJsFile)) {
								this.$emit('script-form:successed', this.script);
								this.$emit('script-form:loading', false);
							}
						}

						if (!_.isNil(this.eventJsFile)) {
							await this.uploadJs()
								.then(async (response) => {
									if (response) {
										await updateScript(this.script.id, {
											processingJsXid: this.script.processingJsXid
										}).then((data) => {
											if (data) {
												this.$emit('script-form:successed', response);
												this.$emit('script-form:loading', false);
											}
										});
									}
								})
								.catch((error) => {
									this.createToastForMobile(
										this.FormMSG(37, 'Not valid file JS'),
										this.FormMSG(38, 'Please uplaod a file valid.'),
										'',
										'danger'
									);
									this.$emit('script-form:loading', false);
									this.eventJsFile = null;
									this.script.fileNameJs = '';
								});
						}
					})
					.catch((error) => {
						const { graphQLErrors } = error;

						if (graphQLErrors && graphQLErrors[0].status === 400) {
							this.createToastForMobile(
								this.FormMSG(41, 'Error'),
								!_.isNil(graphQLErrors[0].message) && !_.isEmpty(graphQLErrors[0].message) ? capitalize(graphQLErrors[0].message) : '',
								'',
								'danger'
							);
						}
						this.$emit('script-form:loading', false);
					});
			}
		},
		async getLicenses() {
			await getLicenses().then((records) => {
				records.sort((a, b) => {
					if (a.license < b.license) {
						return -1;
					}
					if (a.license > b.license) {
						return 1;
					}

					return 0;
				});

				this.licenseOptions[0] = Object.assign(this.licenseOptions[0], {
					id: null,
					label: this.FormMSG(18, 'Select a license ...')
				});

				this.licenseOptions = [...records];

				this.licenseOptions = this.licenseOptions.map((license) => ({
					id: parseInt(license.id, 10),
					label: license.license + ' (' + license.id + ')',
					license
				}));
			});
		},
		async handleChangeLicense(payload, launch = false) {
			if (!isNil(payload)) {
				this.script.companyName = payload.license.companyName;
			}
			if (this.script.licenceId && this.script.licenceId > 0) {
				if (!launch) {
					this.script.projectId = null;
				}
				await getProjects(this.script.licenceId).then((records) => {
					records.sort((a, b) => {
						if (a.titre < b.titre) {
							return -1;
						}
						if (a.titre > b.titre) {
							return 1;
						}

						return 0;
					});

					if (this.projectOptions.findIndex((project) => project.id === null) !== -1) {
						this.projectOptions[0] = Object.assign(this.projectOptions[0], {
							id: null,
							titre: this.FormMSG(19, 'Select a project ...')
						});
					} else {
						this.projectOptions = [
							{
								id: null,
								titre: this.FormMSG(19, 'Select a project ...')
							}
						];
					}

					this.projectOptions = [...this.projectOptions, ...records];

					this.projectOptions = this.projectOptions.map((project) => {
						return {
							id: parseInt(project.id, 10),
							licenseId: parseInt(project.licenseID, 10),
							titre: project.titre
						};
					});
				});
			}
		},
		deleteSelectedProject(payload) {
			if (payload === null) {
				if (this.projectOptions.findIndex((project) => project.titre === 'Select a project ...') === -1) {
					this.projectOptions.unshift({
						id: null,
						titre: this.FormMSG(19, 'Select a project ...')
					});
				}
				this.script.projectId = null;
			}
		},
		handleChangeFileToUploaded(ev) {
			if (ev.type === 'drop') {
				this.eventFile = ev.dataTransfer.files[0];
			} else {
				this.eventFile = ev.target.files[0];
			}
			if (isFileSupported(this.eventFile, [this.defaultScriptAcceptFiles])) {
				this.script.fileName = this.eventFile.name;
				const fileReader = new FileReader();
				fileReader.readAsDataURL(this.eventFile);
			} else {
				this.createToastForMobile(
					this.FormMSG(123, 'Error'),
					this.eventFile.name + ' : ' + this.FormMSG(124, 'The file format is not supported. Please upload a supported file.'),
					'',
					'danger'
				);
				this.$refs.scriptFile.reset();
				this.isLoading = false;
			}
		},
		handleChangeFileJsToUploaded(ev) {
			if (ev.type === 'drop') {
				this.eventJsFile = ev.dataTransfer.files[0];
			} else {
				this.eventJsFile = ev.target.files[0];
			}
			if (isFileSupported(this.eventJsFile, [this.supportedMimeTypesForJsInput])) {
				this.script.fileNameJs = this.eventJsFile.name;

				const fileReader = new FileReader();
				fileReader.readAsDataURL(this.eventJsFile);
			} else {
				this.createToastForMobile(
					this.FormMSG(123, 'Error'),
					this.eventJsFile.name + ' : ' + this.FormMSG(124, 'The file format is not supported. Please upload a supported file.'),
					'',
					'danger'
				);
				this.$refs.javascriptFile.reset();
				this.isLoading = false;
			}
		},
		async upload() {
			return await new Promise(async (resolve, reject) => {
				let formData = new FormData();
				formData.append('uploadimage', this.eventFile);
				formData.append('fileName', this.eventFile.name);
				formData.append('fileType', this.eventFile.type);
				formData.append('parentId', this.script.id);
				formData.append('parentType', this.parentType);
				formData.append('parentSubType', this.script.scriptType);

				const urlStr = process.env.VUE_APP_GQL + this.uploadUrl;
				await axios
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((result) => {
						this.script.templateImageChildXid = result.data.substring(0, result.data.lastIndexOf('.'));
					})
					.catch((error) => {
						console.log({ error });
					});

				setTimeout(() => {
					resolve(this.script);
					reject(false);
				}, 500);
			});
		},
		async uploadJs() {
			return await new Promise(async (resolve, reject) => {
				let formData = new FormData();
				formData.append('uploadimage', this.eventJsFile);
				formData.append('fileName', this.eventJsFile.name);
				formData.append('fileType', this.eventJsFile.type);
				formData.append('parentId', this.script.id);
				formData.append('parentType', this.parentType);
				formData.append('parentSubType', this.script.scriptType);

				const urlStr = process.env.VUE_APP_GQL + this.uploadUrl;
				await axios
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((result) => {
						this.script.processingJsXid = result.data.substring(0, result.data.lastIndexOf('.'));
						this.isSubmitted = false;
					})
					.catch((error) => {
						reject(error);
					});

				setTimeout(() => {
					resolve(this.script);
					reject(false);
				}, 500);
			});
		},
		getExtensionFile(filename) {
			return filename.substring(filename.lastIndexOf('.') + 1);
		},
		handleTypeChanged(payload) {
			const { value } = payload;
			if (!_.isNil(payload) && !_.isEmpty(this.script.fileName) && !_.isNil(this.eventFile)) {
				if (value === 20) {
					if (!['js'].includes(this.getExtensionFile(this.script.fileName))) {
						this.script.fileName = '';
						this.eventFile = null;
						this.fileToUploaded = null;
						const message = this.FormMSG(68, 'Only JS file is acceptable.');
						this.createToastForMobile(this.FormMSG(41, 'Error'), message, '', 'danger');
					}
				} else if (value === 24) {
					if (!['json'].includes(this.getExtensionFile(this.script.fileName))) {
						this.script.fileName = '';
						this.eventFile = null;
						this.fileToUploaded = null;
						const message = this.FormMSG(70, 'Only Json file is acceptable.');
						this.createToastForMobile(this.FormMSG(41, 'Error'), message, '', 'danger');
					}
				} else {
					if (!['xlsx', 'xlsm'].includes(this.getExtensionFile(this.script.fileName))) {
						this.script.fileName = '';
						this.eventFile = null;
						this.fileToUploaded = null;
						const message = this.FormMSG(69, 'Only Excel file is acceptable.');
						this.createToastForMobile(this.FormMSG(41, 'Error'), message, '', 'danger');
					}
				}
			}
		},
		getOdooBackLog() {
			this.forceDownload(this.odooBackLogXid);
		}
	},
	validations() {
		let scriptMandatories = {
			script: {
				licenceId: {
					required
				},
				name: {
					required
				},
				fileName: {
					required
				},
				type: {
					required
				},
				language: {
					required
				},
				outputFileFormat: {
					required
				},
				projectId: {}
			}
		};

		if (this.script.outputFileFormat === 5) {
			scriptMandatories.script = _.assign(scriptMandatories.script, {
				customExtension: {
					required,
					extensiontValid
				}
			});
		}

		if (this.isCertifiedScript) {
			scriptMandatories.script.outputFileFormat = {
				required: {}
			};
		}

		if (this.isComputationSalary || this.isOdoo) {
			scriptMandatories.script.language = {
				required: {}
			};

			scriptMandatories.script.outputFileFormat = {
				required: {}
			};

			scriptMandatories.script.projectId = {
				required
			};
		}

		return scriptMandatories;
	},
	watch: {
		item: {
			handler(item) {
				if (!isNil(item)) {
					setTimeout(async () => {
						this.script = {
							...item
						};
						await this.handleChangeLicense(null, true);
					}, 250);
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss">
.info-script {
	margin-top: 12px;
	font-size: 1rem;
	font-weight: 600;
}
</style>
